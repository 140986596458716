import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import MetaDetails from "../widgets/MetaDetails";
import { useAtom } from "jotai";
import { accountsAtom, initLoadingAtom } from "../helper/store";
import { getAccounts } from "../services/HoldingService";
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { getExchangeConfig } from "../helper/platformConfig";

const Layout = ({ children, className }) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [initLoading, setInitLoading] = useAtom(initLoadingAtom);
  const [accounts, setAccounts] = useAtom(accountsAtom);

  useEffect(() => {
    if (accounts.length === 0) getAccountsAPI();
  }, []);
  const getAccountsAPI = async () => {
    const { status, data } = await getAccounts();
    if (status) {
      setAccounts(data);
    }
    setInitLoading(false);
  };

  useEffect(() => {
    const tokenData = localStorage.getItem("okta-token-storage");
    if (!tokenData) {
      return navigate("/login", { replace: true });
    }
    if (tokenData) {
      const {
        tokenDetails: { exp },
      } = JSON.parse(tokenData);
      if (exp < Math.floor(Date.now() / 1000)) {
        navigate("/login", { replace: true });
      }
    }
  }, []);
  const exchangeConfig = getExchangeConfig();
  return (
    <>
      <MetaDetails
        title={exchangeConfig.name}
        description={exchangeConfig.name}
        favicon={require(`../${exchangeConfig.favicon}`)}
      />
      <main className="relative h-screen overflow-hidden">
        <Header menu={menu} setMenu={setMenu} />
        {!initLoading && (
          <div className="relative flex items-start h-[calc(100vh-70px)] 2xl:h-[calc(100vh-100px)]">
            <Sidebar menu={menu} setMenu={setMenu} />
            <div
              className={`relative h-full flex-1 px-6 py-8 xl:p-8 overflow-auto overflow-x-hidden ${className} bg-mainBackground`}
            >
              {children}
            </div>
          </div>
        )}

        {initLoading && (
          <div className="text-center py-10 flex justify-center">
            <Bars
              height="80"
              width="80"
              color="rgb(78,183,63)"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass="bars-loading"
              visible={true}
            />
          </div>
        )}
      </main>
    </>
  );
};

export default Layout;
