const hModel = {
  parseList(data, accounts = []) {
    var items = [];
    data.forEach((element) => {
      const accountResult = accounts.filter(
        (val) => val.id === element["account-id"]
      );
      items.push({
        account: accountResult.length > 0 ? accountResult[0].name : "",
        symbol: element.asset.code,
        type: accountResult.length > 0 ? accountResult[0].type : "",
        name: element.asset.description,
        inOrder: element.quantities["in-order"],
        withdrawing: element.quantities["pending-withdrawal"],
        available: element.quantities.available,
      });
    });
    return items;
  },
  parseGroups(data) {
    var items = [];
    data.forEach((element) => {
      items.push({
        id: element.id,
        name: element.name,
        longName: element["long-name"],
      });
    });
    return items;
  },
  parseAccount(data) {
    var items = [];
    data.forEach((element) => {
      items.push({
        id: element.id,
        name: element.name,
        longName: element["long-name"],
        type: element.type,
        status: element.status,
        email: element.email,
      });
    });
    return items;
  },
};
export default hModel;
