import React, { useEffect, useState } from "react";
import Layout from "../shared/Layout";
import Table from "../widgets/Table";
import { docColumns } from "../data/DocumentsTableData";
import Pagination from "../widgets/Pagination";
import PageTitle from "../widgets/PageTitle";
import { getDocuments, getDocumentTypes } from "../services/DocumentService";
import { Bars } from "react-loader-spinner";
import { useAtom } from "jotai";
import { accountsAtom, initLoadingAtom } from "../helper/store";
import TypeFilter from "../components/Filters/TypeFilter";
import RangeFilter from "../components/Filters/RangeFilter";
import moment from "moment";
import DocumentSort from "../components/Sorts/DocumentSort";
import { getExchangeConfig } from "../helper/platformConfig";

const Documents = () => {
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [accounts] = useAtom(accountsAtom);
  const [initLoading] = useAtom(initLoadingAtom);

  const [documentTypes, setDocumentTypes] = useState([]);
  const [currentType, setCurrentType] = useState("all");
  const [sortType, setSortType] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    initFields();
  }, []);

  useEffect(() => {
    if (!initLoading) {
      setLoading(true);
      getDocumentAPI(currentPage, currentType, startDate, endDate, sortType);
    }
  }, [currentPage, currentType, initLoading, startDate, endDate, sortType]);

  const initFields = () => {
    getTypeAPI();
  };

  const getTypeAPI = () => {
    getDocumentTypes().then((result) => {
      let documentTypeData = [];
      if (result.status) {
        documentTypeData = result.data;
      }
      setDocumentTypes(documentTypeData);
      setCurrentType("all");
    });
  };

  const getDocumentAPI = (page, type, dateStart, dateEnd, sortBy) => {
    getDocuments({
      page,
      type,
      accounts,
      dateStart,
      dateEnd,
      sortBy,
    }).then((result) => {
      let documentData = [];
      if (result.status) {
        documentData = result.data;
        if (documentData.length === 0) {
          setErrorMsg("No Data Available");
        }
        setTotalPages(result.totalRows);
      } else {
        setErrorMsg(
          result.statusCode === 401
            ? "You are not authorised."
            : "No Data Available"
        );
      }
      setData(documentData);
      setLoading(false);
    });
  };

  const onPageChange = (pagingType) => {
    if (loading) {
      return;
    }
    if (pagingType === "next") {
      setCurrentPage(currentPage + 10);
    } else {
      setCurrentPage(currentPage - 10);
    }
  };

  const onFilterchange = (filterType, filterValue) => {
    if (loading) {
      return;
    }
    setCurrentPage(0);
    if (filterType === "range") {
      let newStartDate = null;
      let newEndDate = null;
      if (filterValue == null) {
        setStartDate(null);
        setEndDate(null);
      } else {
        if (filterValue[0] == null) {
          setStartDate(null);
          setEndDate(null);
        } else {
          newStartDate = moment(filterValue[0]).format("YYYY-MM-DD");
          newEndDate = moment(filterValue[1]).format("YYYY-MM-DD");
          setStartDate(newStartDate);
          setEndDate(newEndDate);
        }
      }
    } else {
      setCurrentType(filterValue.value);
    }
  };

  const onSortchange = (_, selectedOption) => {
    if (loading) {
      return;
    }
    setCurrentPage(0);
    setSortType(selectedOption.value);
  };

  return (
    <Layout className="space-y-6">
      <div className="relative lg:flex xl:flex items-center justify-between gap-6">
        <PageTitle title={getExchangeConfig().sidebarLabels[1]} level={1} />
        <div className="relative flex items-center xl:justify-end lg:justify-end gap-6 mt-10 xl:mt-0 lg:mt-0">
          <RangeFilter
            startDate={null}
            endDate={null}
            onFilterchange={onFilterchange}
          />
          <TypeFilter data={documentTypes} onFilterchange={onFilterchange} />
          <DocumentSort
            data={[
              {
                label: "None",
                value: "none",
              },
              {
                label: "Description",
                value: "name",
              },
              {
                label: "Type",
                value: "type",
              },
              // {
              //   label: "Size",
              //   value: "size",
              // },
              {
                label: "Date",
                value: "date-published",
              },
            ]}
            onFilterchange={onSortchange}
          />
        </div>
      </div>
      {loading && (
        <div className="text-center py-10 flex justify-center">
          <Bars
            height="80"
            width="80"
            color="rgb(78,183,63)"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="bars-loading"
            visible={true}
          />
        </div>
      )}
      {!loading && data.length > 0 && (
        <Table
          // className="max-h-[calc(100vh-294px)]"
          columns={docColumns}
          data={data}
        />
      )}
      {!loading && data.length === 0 && (
        <div className="text-center py-10">{errorMsg}</div>
      )}

      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPage={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </Layout>
  );
};

export default Documents;
