import { api } from "../helper/config";
import HoldingModel from "../models/HoldingModel";
import axios from "axios";
import { getAPIHeader, parseJWTDetail } from "./CommonService";
import { getAPIParamLimit } from "../helper/platformConfig";

export const getGroups = () => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/participant/" +
      parseJWTDetail("participant") +
      "/groups?start-row=0&rows=100";

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        let pageDetails = response.data["page-details"];
        resolve({
          status: true,
          statusCode: response.status,
          data: response.data.data
            ? HoldingModel.parseGroups(response.data.data)
            : [],
          totalRows: pageDetails["total-rows"],
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};

export const getAccounts = async () => {
  // return new Promise((resolve, reject) => {
  //   let header = getAPIHeader();
  //   var url =
  //     window.env.REACT_APP_BASE_URI +
  //     api.EXCHANGE() +
  //     "/participant/" +
  //     parseJWTDetail("participant") +
  //     "/accounts?start-row=0&rows=100";

  //   axios
  //     .get(url, {
  //       headers: header,
  //     })
  //     .then((response) => {
  //       let pageDetails = response.data["page-details"];
  //       resolve({
  //         status: true,
  //         statusCode: response.status,
  //         data: response.data.data
  //           ? HoldingModel.parseAccount(response.data.data)
  //           : [],
  //         totalRows: pageDetails["total-rows"],
  //       });
  //     })
  //     .catch((error) => {
  //       let statusCode = 404;
  //       if (error.response) {
  //         statusCode = error.response.status;
  //       }
  //       resolve({
  //         status: false,
  //         statusCode: statusCode,
  //         message: error.message,
  //       });
  //     });
  // });
  try {
    const allAccountsData = await iterateAndFetchAccounts();
    return {
      status: true,
      statusCode: 200,
      data: allAccountsData.data
        ? HoldingModel.parseAccount(allAccountsData.data)
        : [],
      totalRows: allAccountsData.totalRecords,
    };
  } catch (error) {
    return {
      status: false,
      statusCode: error.code,
      message: error.message,
    };
  }
};

const accountsCall = async (startRow) => {
  let header = getAPIHeader();
  var url = `${window.env.REACT_APP_BASE_URI
    }${api.EXCHANGE()}/participant/${parseJWTDetail(
      "participant"
    )}/accounts?start-row=${startRow}&rows=${getAPIParamLimit}`;
  const data = await axios.get(url, {
    headers: header,
  });
  return data.data;
};

const iterateAndFetchAccounts = async () => {
  const results = await accountsCall(0);
  const totalRecords = results["page-details"]["total-rows"];
  const totalRoundsOfApiCallsNeeded = Math.ceil(
    totalRecords / getAPIParamLimit
  );
  if (totalRoundsOfApiCallsNeeded > 1) {
    for (
      let recordIndex = 1;
      recordIndex < totalRoundsOfApiCallsNeeded;
      recordIndex++
    ) {
      const nextData = await accountsCall(recordIndex * getAPIParamLimit);
      results.data = [...results.data, ...nextData.data];
    }
  }
  return { ...results, totalRecords };
};

export const getHoldings = (params) => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/participant/" +
      parseJWTDetail("participant") +
      "/holdings?start-row=" +
      params.page;
    if (params.sortBy) {
      if (params.sortBy === "asset.code") {
        url = url + "&sort-by=" + params.sortBy + "&order=DESC";
      } else {
        url = url + "&sort-by=" + params.sortBy;
      }
    }
    url += `&include-zeros=${params.showZeroBalance}`;
    if (params.symbolSearch) {
      url += `&asset-code=${params.symbolSearch}`;
    }
    if (params.nameSearch) {
      url += `&asset-description=${params.nameSearch}`;
    }
    if (params.accountSearch) {
      url += `&account-name=${params.accountSearch}`
    }
    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        let pageDetails = response.data["page-details"];
        resolve({
          status: true,
          statusCode: response.status,
          data: response.data.data
            ? HoldingModel.parseList(response.data.data, params.accounts)
            : [],
          totalRows: pageDetails["total-rows"],
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};
