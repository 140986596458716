import React from "react";
import Button from "../widgets/Button";
import routes from "../routes/routes";
import { useNavigate } from "react-router-dom";
import { getUsername } from "../services/CommonService";
import { getExchangeConfig } from "../helper/platformConfig";

const Sidebar = ({ menu, setMenu }) => {
  const navigate = useNavigate();
  const logout = async () => {
    localStorage.clear();
    const auth0Lock = require("../helper/auth0").auth0Lock;
    auth0Lock.logout({ returnTo: window.location.origin + "/login" });
  };
  const menuList = [
    {
      href: routes.listing,
      title: getExchangeConfig().sidebarLabels[0],
    },
    {
      href: routes.documents,
      title: getExchangeConfig().sidebarLabels[1],
    },
    {
      href: routes.holding,
      title: getExchangeConfig().sidebarLabels[2],
    },
  ];

  return (
    <aside
      className={`${
        menu ? "max-lg:left-0" : "max-lg:-left-full"
      } max-lg:absolute relative px-4 py-5 2xl:py-8 flex flex-col justify-between h-full w-64 2xl:w-72 bg-foreground transition-all z-50`}
    >
      <div className="flex flex-col gap-3 2xl:gap-4">
        {menuList.map((items, i) => (
          <Button
            key={i}
            title={items.title}
            onClick={() => {
              setMenu(false);
              navigate(items.href);
            }}
            size="lg"
          />
        ))}
      </div>

      <div className="flex flex-col gap-3">
        <Button title="Logout" size="lg" onClick={logout} />
        <div>
          <p className="text-sm text-black text-center w-full line-clamp-2">
            Logged on as <span className="capitalize">{getUsername()}</span>
          </p>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
